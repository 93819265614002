const shortenedAddress = (content, prefixLength=10) => {
  if (content.length > 30) {
    return content.slice(0, prefixLength) + "..." + content.slice(-prefixLength);
  } else {
    return content;
  }
}

module.exports = {
  shortenedAddress
}