import React, { useEffect, useState } from "react";
import { auth } from "../firebase";
import CopyButton from "../copyButton";
import Notification from "../notification";
import ResetTestnet from "./actionModals/resetTestnet";
import GetETH from "./actionModals/getETH.jsx";
import GetERC20 from "./actionModals/getERC20.jsx";
import DeployERC20Token from "./actionModals/deployERC20.jsx";
import AddLiquidityToUniswapV2 from "./actionModals/addLiquidityToUniswapV2.jsx";
import axios from "axios";
import { shortenedAddress } from "../utils";
import MetamaskLogo from "../../assets/MetaMask_Fox.svg";

const backend = process.env.REACT_APP_BACKEND_URL;

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export default function Dashboard() {
  const [addressesLoading, setAddressesLoading] = useState(false);
  const [addressesMetadata, setAddressesMetadata] = useState([]);

  const [liquidityPoolsLoad, setLiquidityPoolsLoad] = useState(false);
  const [liquidityPools, setLiquidityPools] = useState([]);

  const [transactionsLoading, setTransactionsLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);

  const [configs, setConfigs] = useState([]);
  const [configsLoading, setConfigsLoading] = useState(false);

  const [notificationMsg, setNotificationMsg] = useState(null);
  const [notificationSubTitle, setNotificationSubTitle] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [currentModal, setCurrentModal] = useState(null);

  const [showLoadingModal, setShowLoadingModal] = useState(false);

  const [testnetUrl, setTestnetUrl] = useState(null);
  const [testnetUrlLoaded, setTestnetUrlLoaded] = useState(false);

  const [waitForTestnet, setWaitForTestnet] = useState(false);

  const [walletChainId, setWalletChainId] = useState(null);

  const [connectedWallet, setConnectedWallet] = useState(null);

  const showNotification = (msg, subTitle) => {
    setNotificationMsg({ msg, error: false });
    setNotificationSubTitle(subTitle);
    setTimeout(() => {
      setNotificationMsg(null);
      setNotificationSubTitle("");
    }, 1500);
  };

  const showErrorNotification = (msg, subTitle = "") => {
    setNotificationMsg({ msg, error: true });
    setNotificationSubTitle(subTitle);
    setTimeout(() => {
      setNotificationMsg(null);
      setNotificationSubTitle("");
    }, 1500);
  };

  const updateTestnetUrl = async (url) => {
    const authToken = await auth.currentUser?.getIdToken();

    // Create the headers object with the authorization header
    const headers = {
      "Content-Type": "application/json",
      Authorization: `${authToken}`,
    };

    const postData = {
      // Your post data here, for example:
      testnetUrl: url
    };

    await fetch(`${backend}/updateTestnetUrl`, {
      method: "POST",
      headers,
      body: JSON.stringify(postData), // Convert the post data to JSON format
    });
  };


  async function checkNodeStatus() {
    const authToken = await auth.currentUser?.getIdToken();

    // Create the headers object with the authorization header
    const headers = {
      "Content-Type": "application/json",
      Authorization: `${authToken}`,
    };


    while (true) {
      setWaitForTestnet(true)
      try {
        const response = await fetch(`${backend}/getTestnetNodeInfo`, {
          headers,
        });
        const data = await response.json();

        if (data.conditions[0].state === "CONDITION_SUCCEEDED") {
          setWaitForTestnet(false)
          setTestnetUrlLoaded(true);
          setTestnetUrl(`${backend}/jsonrpc/${auth.currentUser.uid}`);
          updateTestnetUrl(data.uri);
          showNotification("Testnet node is ready!", "Refresh dashboard to view the node url.");
          return;
        }
      } catch (error) {
        alert(error);
        showErrorNotification("Error fetching testnet node status");
      }
      console.log("delaying...");
      await delay(1000);
    }
  }

  const launchTestnet = async () => {
    try {
      // Wait until the user is authenticated
      await new Promise((resolve) => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
          if (user) {
            resolve();
          }
        });
        return unsubscribe;
      });

      // Get the Firebase auth token
      const authToken = await auth.currentUser?.getIdToken();

      // Create the headers object with the authorization header
      const headers = {
        "Content-Type": "application/json",
        Authorization: `${authToken}`,
      };

      // Send the POST request to create or fetch the API key
      fetch(`${backend}/launchTestnetNode`, {
        method: "POST",
        headers,
      })
        .then((response) => response.json())
        .then((data) => {
          setTestnetUrlLoaded(false);
          checkNodeStatus();
        })
        .catch((error) => {
          showErrorNotification("Error launching testnet:");
        });
    } catch (error) {
      showErrorNotification("Error launching testnet");
    }
  };

  const displayLoadingModal = () => {
    window.loadingModal.showModal();
  };

  const onOperationSuccess = (msg, subTitle) => {
    refreshAll();
    window.loadingModal.close();
    showNotification(msg, subTitle);
  };

  const handleResetTestnet = async () => {
    setCurrentModal(
      <ResetTestnet
        onSuccess={onOperationSuccess}
        onClickConfirm={displayLoadingModal}
      />
    );
    setShowModal(true);
    window.my_modal.showModal();
  };

  const addTestnetToWallet = async () => {
    const { ethereum } = window;
    if (!ethereum) {
      showErrorNotification("No web3 wallet is found in your browser");
      return;
    }
    try {
      await ethereum.request({
        "method": "wallet_addEthereumChain",
        "params": [
          {
            // hardcode for now, but should be fetched from backend
            "chainId": "0x7a69",
            "chainName": "Trestles (Ethereum mainnet fork)",
            "rpcUrls": [
              `${testnetUrl}`
            ],
            "nativeCurrency": {
              "name": "ETH",
              "symbol": "ETH",
              "decimals": 18
            },
          }
        ]
      });
      // showNotification(`Wallet connected: ${accounts[0]}`);
      setConnectedWallet(ethereum.selectedAddress);
      setWalletChainId("0x7a69");
    } catch (error) {
      alert(error);
      showErrorNotification("Error connecting wallet");
    }
  };

  const connectWallet = async () => {
    const { ethereum } = window;
    try {
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });
      setConnectedWallet(ethereum.selectedAddress);
    } catch (error) {
      showErrorNotification("Error connecting wallet");
    }
  };

  const copyConnectedWallet = (event) => {
    event.preventDefault();
    navigator.clipboard.writeText(connectedWallet);
    showNotification("Copied to clipboard", "")
  };


  const handleActionClick = (action) => {
    if (!action.modal) {
      if (action.onClick) {
        action.onClick();
      }
      return;
    }
    setCurrentModal(action.modal);
    setShowModal(true);
    window.my_modal.showModal();
  };

  const fetchAddresses = async () => {
    setAddressesLoading(true);
    try {
      // Wait until the user is authenticated
      await new Promise((resolve) => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
          if (user) {
            resolve();
          }
        });
        return unsubscribe;
      });

      // Get the Firebase auth token
      const authToken = await auth.currentUser?.getIdToken();

      // Create the headers object with the authorization header
      const headers = {
        "Content-Type": "application/json",
        Authorization: `${authToken}`,
      };

      // Send the POST request to create or fetch the API key
      fetch(`${backend}/getAddresses`, {
        method: "GET",
        headers,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log('......')
          console.log(data);
          setAddressesLoading(false);
          setAddressesMetadata(data.addresses);
        })
        .catch((error) => {
          // Handle the error
          setAddressesLoading(false);
          showErrorNotification("Error fetching addresses");
        });
    } catch (error) {
      setAddressesLoading(false);
      showErrorNotification("Error fetching addresses");
    }
  };

  const fetchTestnetUrl = async () => {
    try {
      // Wait until the user is authenticated
      await new Promise((resolve) => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
          if (user) {
            resolve();
          }
        });
        return unsubscribe;
      });

      // Get the Firebase auth token
      const authToken = await auth.currentUser?.getIdToken();

      // Create the headers object with the authorization header
      const headers = {
        "Content-Type": "application/json",
        Authorization: `${authToken}`,
      };

      const response = await fetch(`${backend}/getTestnetNodeInfo`, {
        method: "GET",
        headers,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }

      const data = await response.json();
      console.log(data.uri)
      if (data.conditions[0].state && data.conditions[0].state !== "CONDITION_SUCCEEDED") {
        setTestnetUrlLoaded(false);
        checkNodeStatus();
        return;
      }
      setTestnetUrl(`${backend}/jsonrpc/${auth.currentUser.uid}`);
    } catch (error) {
      // TODO: handle error
      // showErrorNotification("Error fetching testnet url");
    }
    setTestnetUrlLoaded(true);
  };

  const fetchLiquidityPool = async () => {
    try {
      // Wait until the user is authenticated
      await new Promise((resolve) => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
          if (user) {
            resolve();
          }
        });
        return unsubscribe;
      });

      // Get the Firebase auth token
      const authToken = await auth.currentUser?.getIdToken();

      // Create the headers object with the authorization header
      const headers = {
        "Content-Type": "application/json",
        Authorization: `${authToken}`,
      };

      // Send the POST request to create or fetch the API key
      fetch(`${backend}/getLiquidityPools`, {
        method: "GET",
        headers,
      })
        .then((response) => response.json())
        .then((data) => {
          setLiquidityPools(data.liquidityPools);
          setLiquidityPoolsLoad(true);
        })
        .catch((error) => {
          // Handle the error
          showErrorNotification("Error fetching addresses");
          setLiquidityPoolsLoad(true);
        });
    } catch (error) {
      showErrorNotification("Error fetching addresses");
      setLiquidityPoolsLoad(true);
    }
  };

  const fetchTransactions = async () => {
    setTransactionsLoading(true);
    try {
      // Wait until the user is authenticated
      await new Promise((resolve) => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
          if (user) {
            resolve();
          }
        });
        return unsubscribe;
      });

      // Get the Firebase auth token
      const authToken = await auth.currentUser?.getIdToken();

      // Create the headers object with the authorization header
      const headers = {
        "Content-Type": "application/json",
        Authorization: `${authToken}`,
      };

      // Send the POST request to create or fetch the API key
      fetch(`${backend}/getTransactions`, {
        method: "GET",
        headers,
      })
        .then((response) => response.json())
        .then((data) => {
          setTransactions(data.transactions);
          setTransactionsLoading(false);
        })
        .catch((error) => {
          // Handle the error
          showNotification("Error fetching addresses:", error, true);
          setTransactionsLoading(false);
        });
    } catch (error) {
      showNotification("Error fetching addresses:", error, true);
      setTransactionsLoading(false);
    }
  };

  const fetchConfigs = async () => {
    setConfigsLoading(true);
    try {
      // Wait until the user is authenticated
      await new Promise((resolve) => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
          if (user) {
            resolve();
          }
        });
        return unsubscribe;
      });

      // Get the Firebase auth token
      const authToken = await auth.currentUser?.getIdToken();

      // Create the headers object with the authorization header
      const headers = {
        "Content-Type": "application/json",
        Authorization: `${authToken}`,
      };

      // Send the POST request to create or fetch the API key
      fetch(`${backend}/getConfigs`, {
        method: "GET",
        headers,
      })
        .then((response) => response.json())
        .then((data) => {
          // alert(JSON.stringify(data.configs))
          setConfigs(data.configs);
          setConfigsLoading(false);
        })
        .catch((error) => {
          // Handle the error
          showErrorNotification("Error fetching addresses");
          setConfigsLoading(false);
        });
    } catch (error) {
      showErrorNotification("Error fetching addresses");
      setConfigsLoading(false);
    }
  };

  const loadWalletChainId = async () => {
    const { ethereum } = window;
    if (ethereum) {
      try {
        setWalletChainId(ethereum.chainId);
        setConnectedWallet(ethereum.selectedAddress);
      } catch (error) {
        showErrorNotification("Error connecting wallet");
      }
    }
  };

  const refreshAll = () => {
    fetchAddresses();
    fetchConfigs();
    fetchTransactions();
    // fetchLiquidityPool();
  };
  useEffect(() => {
    if (!testnetUrl) {
      fetchTestnetUrl();
    }
    if (testnetUrl) {
      refreshAll();
      loadWalletChainId();
    }
  }, [testnetUrl]);

  const quickStartActions = [
    {
      name: "Create new wallet",
      // modal: <GenerateNewAddress />,
      onClick: () => {
        window.loadingModal.showModal();
        auth.currentUser
          .getIdToken()
          .then((idToken) => {
            axios
              .post(
                `${backend}/generateNewAddress`,
                {},
                {
                  headers: {
                    Authorization: idToken,
                  },
                }
              )
              .then((response) => {
                showNotification(
                  `Address ${shortenedAddress(
                    response.data.address
                  )} is generated!`,
                  "Refresh dashboard to view the address."
                );
                refreshAll();
                window.loadingModal.close();
              })
              .catch((error) => {
                showErrorNotification("Error generating new address");
              });
          })
          .catch((error) => {
            showErrorNotification("Error generating new address");
          });
      },
    },
    {
      name: "Get ETH",
      modal: (
        <GetETH
          onSuccess={onOperationSuccess}
          onClickConfirm={displayLoadingModal}
        />
      ),
    },
    {
      name: "Get ERC20",
      modal: (
        <GetERC20
          onSuccess={onOperationSuccess}
          onClickConfirm={displayLoadingModal}
        />
      ),
    },
    // {
    //   name: "Deploy your own contract",
    //   modal: <DeployContract />,
    // },
  ];

  const defiActions = [
    {
      name: "Deploy new ERC20 token",
      modal: (
        <DeployERC20Token
          onSuccess={onOperationSuccess}
          onClickConfirm={displayLoadingModal}
        />
      ),
    },
    // {
    //   name: "Add liquidity to Uniswap V2",
    //   modal: (
    //     <AddLiquidityToUniswapV2
    //       showNotification={showNotification}
    //       onConfirm={refreshAll}
    //     />
    //   ),
    // },
    // {
    // name: "Add liquidity to Uniswap V3",
    // modal: < />,
    // },
  ];

  return (
    <div>
      {notificationMsg && (
        <Notification
          message={notificationMsg.msg}
          subTitle={notificationSubTitle}
          error={notificationMsg.error}
        />
      )}
      <div className="flex justify-between mb-4">
        <div className="flex items-center">
          <p className="mr-2 text-gray-900 font-bold">testnet JSON-RPC url:</p>
          {!testnetUrlLoaded ? <button className="btn btn-info">
            <span className="loading loading-spinner loading-sm"></span>
            {waitForTestnet && "Testnet node is starting..."}
          </button> :
            testnetUrl ? (
              <CopyButton
                content={`${testnetUrl}`}
                showNotification={showNotification}
              />) :
              <button className="btn btn-info" onClick={launchTestnet}>
                Launch testnet
              </button>
          }
        </div>
        {testnetUrl ?
          (walletChainId === "0x7a69" ?
            <button className="btn" onClick={connectedWallet ? copyConnectedWallet : connectWallet} style={{ textTransform: "none" }}>
              <img src={MetamaskLogo} className="w-6 h-6 mr-2" />
              {connectedWallet ? `${shortenedAddress(connectedWallet)}` : "Connect wallet"}
            </button> : <button className="btn" onClick={addTestnetToWallet} style={{ textTransform: "none" }}>
              <img src={MetamaskLogo} className="w-6 h-6 mr-2" />
              Add testnet network to wallet
            </button>) :
          <button className="btn btn-disabled" onClick={addTestnetToWallet} style={{ textTransform: "none" }}>
            <img src={MetamaskLogo} className="w-6 h-6 mr-2" />
            Add testnet network to wallet
          </button>
        }
      </div>
      {testnetUrl ? (
        <button
          className="btn btn-outline flex-none btn-accent"
          onClick={handleResetTestnet}
        >
          reset testnet
        </button>
      ) : (
        <button
          className="btn btn-outline flex-none btn-accent btn-disabled"
          onClick={handleResetTestnet}
        >
          reset testnet
        </button>
      )}

      <ul
        role="list"
        className="mt-5 grid grid-cols-1 gap-6 sm:grid-cols-1 lg:grid-cols-1"
      >
        <li
          key="quick-start"
          className="border border-gray-300 rounded p-4 shadow-lg"
        >
          <h3 className="text-2xl leading-6 text-gray-500 tracking-wider font-orbitron">
            Configure
          </h3>
          <ul role="list" className="mt-3 grid grid-cols-3 gap-5 sm:gap-6">
            {quickStartActions.concat(defiActions).map((action) => (
              <li key={action.name} className="col-span-1">
                {testnetUrl ? (
                  <button
                    className="btn btn-outline"
                    onClick={() => handleActionClick(action)}
                  >
                    {action.name}
                  </button>
                ) : (
                  <button
                    className="btn btn-disabled"
                    onClick={() => handleActionClick(action)}
                  >
                    {action.name}
                  </button>
                )}
              </li>
            ))}
          </ul>
        </li>
      </ul>

      <ul
        role="list"
        className="mt-5 grid grid-cols-1 gap-6 sm:grid-cols-1 lg:grid-cols-1"
      >
        <li>
          <div
            tabIndex={0}
            className={`collapse collapse-arrow border border-base-300 bg-base-200 ${addressesMetadata && Object.keys(addressesMetadata).length > 5 ? "" : "collapse-open"}`}
          >
            <div className="collapse-title text-xl font-medium">
              {addressesMetadata && Object.keys(addressesMetadata).length > 5 ? "Click to see addresses" : "Addresses"}
            </div>
            <div className="collapse-content">
              <div className="overflow-x-auto">
                {addressesLoading ? (
                  <p className="text-center">
                    <span className="loading loading-spinner loading-lg"></span>
                  </p>
                ) : addressesMetadata &&
                  Object.keys(addressesMetadata).length === 0 ? (
                  <p className="text-center">No addresses yet, create some</p>
                ) : (
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Address</th>
                        <th>Private key</th>
                        <th>Type</th>
                        <th>Balances</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(addressesMetadata).map(
                        ([address, metadata]) => (
                          <tr key={address}>
                            <td>{address}</td>
                            <td>{metadata.privateKey}</td>
                            <td>{metadata.type}</td>
                            <td>
                              {metadata.balance &&
                                Object.entries(metadata.balance).map(
                                  ([token, b]) => (
                                    <div key={token}>
                                      {token}: {Number(b).toFixed(5)}
                                    </div>
                                  )
                                )}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </li>

        <li>
          <div
            tabIndex={0}
            className={`collapse collapse-arrow border border-base-300 bg-base-200 ${configs && Object.keys(configs).length > 5 ? "" : "collapse-open"}`}
          >
            <div className="collapse-title text-xl font-medium">{configs && Object.keys(configs).length > 5 ? "Click to see configs" : "Configs"}</div>
            <div className="collapse-content">
              <div className="overflow-x-auto">
                {configsLoading ? (
                  <p className="text-center">
                    <span className="loading loading-spinner loading-lg"></span>
                  </p>
                ) : !configs || configs.length === 0 ? (
                  <p className="text-center">
                    No config yet, start configuring your testing environment
                  </p>
                ) : (
                  <table className="table">
                    <thead>
                      <tr>
                        <th>description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {configs.map((config) => (
                        <tr>
                          <td>{config}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </li>

        <li>
          <div
            tabIndex={0}
            className={`collapse collapse-arrow border border-base-300 bg-base-200 ${transactions && Object.keys(transactions).length > 5 ? "" : "collapse-open"
              }`}
          >
            <div className="collapse-title text-xl font-medium">
              {transactions && Object.keys(transactions).length > 5 ? "Click to see transactions" : "Transactions"}
            </div>
            <div className="collapse-content">
              <div className="overflow-x-auto">
                {transactionsLoading ? (
                  <p className="text-center">
                    <span className="loading loading-spinner loading-lg"></span>
                  </p>
                ) : !transactions || Object.keys(transactions).length === 0 ? (
                  <p className="text-center">
                    No transactions yet, transactions submitted through the JSON
                    RPC URL will appear here
                  </p>
                ) : (
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Transaction hash</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(transactions).map(
                        ([transactionHash, transactionData]) => (
                          <tr key={transactionHash}>
                            <td>{transactionHash}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </li>
        {/* <li>
          <div
            tabIndex={0}
            className="collapse collapse-arrow border border-base-300 bg-base-200 collapse-open"
          >
            <div className="collapse-title text-xl font-medium">
              UniswapV2 liquidity pools
            </div>
            <div className="collapse-content">
              <div className="overflow-x-auto">
                {!liquidityPoolsLoad ? (
                  <p className="text-center">
                    <span className="loading loading-spinner loading-lg"></span>
                  </p>
                ) : liquidityPools &&
                  Object.keys(liquidityPools).length === 0 ? (
                  <p className="text-center">
                    No liquidity pools yet, go to composer to add some liquidity
                    pairs
                  </p>
                ) : (
                  <table className="table">
                    <thead>
                      <tr>
                        <th>token A</th>
                        <th>amount A</th>
                        <th>token B</th>
                        <th>amount B</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(liquidityPools).map(
                        ([lpId, liquidityPool]) => (
                          <tr key={lpId}>
                            <td>{Object.values(liquidityPool)[0].symbol}</td>
                            <td>
                              {(
                                Object.values(liquidityPool)[0].reserve /
                                Math.pow(
                                  10,
                                  Object.values(liquidityPool)[0].decimals
                                )
                              ).toFixed(5)}
                            </td>
                            <td>{Object.values(liquidityPool)[1].symbol}</td>
                            <td>
                              {(
                                Number(
                                  Object.values(liquidityPool)[1].reserve
                                ) /
                                Math.pow(
                                  10,
                                  Object.values(liquidityPool)[1].decimals
                                )
                              ).toFixed(5)}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </li> */}
      </ul>
      <dialog id="my_modal" className="modal">
        {showModal && currentModal && currentModal}
      </dialog>
      <dialog id="loadingModal" className="modal modal-bottom sm:modal-middle">
        <form method="dialog" className="modal-box flex justify-center">
          <span className="loading loading-spinner loading-lg"></span>
        </form>
      </dialog>
    </div>
  );
}
