import { useEffect } from 'react';
import { auth } from '../components/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

function useAuthHook() {
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("User signed in....");
        // User is signed in, no need to redirect
      } else {
        console.log("User signed out");
        // User is signed out, redirect to /sign-in
        navigate('/sign-in');
      }
    });

    return () => {
      unsubscribe();
    };
  }, [navigate]);
}

export default useAuthHook;
