import axios from "axios";
import { auth } from "../../firebase";

const backend = process.env.REACT_APP_BACKEND_URL;

export default function DeployERC20Token({onSuccess, onClickConfirm}) {
  const handleExecute = (event) => {
    const tokenName = document.getElementById("token-name").value;
    const symbol = document.getElementById("symbol").value;
    const totalSupply = document.getElementById("total-supply").value;
    const receivingAddress = document.getElementById("receiving-address").value;

    onClickConfirm();

    auth.currentUser
      .getIdToken()
      .then((idToken) => {
        // Make the POST request to the /deployERC20 endpoint
        axios
          .post(
            `${backend}/deployERC20Token`,
            {
              tokenName,
              symbol,
              totalSupply,
              receivingAddress,
            },
            {
              headers: {
                Authorization: idToken,
              },
            }
          )
          .then((response) => {
            // Handle the response
            onSuccess(
              `ERC20 token is deployed`,
              "Go to dashboard to view the transaction."
            );
          })
          .catch((error) => {
            // Handle the error
            console.error(error);
          });
      })
      .catch((error) => {
        // Handle the error
        console.error(error);
      });
  };

  return (
    <form method="dialog" className="modal-box">
      <div className="sm:col-span-3">
        <input
          type="text"
          id="token-name" // Added an id for easier access
          placeholder="token name"
          className="input input-bordered w-1/3 max-w-xs"
        />
        <input
          type="text"
          id="symbol" // Added an id for easier access
          placeholder="symbol"
          className="input input-bordered w-1/3 max-w-xs ml-5"
        />
      </div>
      <input
        type="number"
        id="total-supply" // Added an id for easier access
        placeholder="total supply"
        className="input input-bordered w-full max-w-xs mt-5"
      />
      <input
        type="text"
        id="receiving-address" // Added an id for easier access
        placeholder="receiving address (optional)"
        className="input input-bordered w-full max-w-xs mt-5"
      />
      <div className="modal-action">
        <div className="flex justify-between">
          <button className="btn mr-2" onClick={handleExecute}>
            Execute
          </button>{" "}
          {/* Added onClick event and attached handleExecute function */}
          <button className="btn ml-2">Close</button>
        </div>
      </div>
    </form>
  );
}
