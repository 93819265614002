import React, { useState } from "react";
import { shortenedAddress } from "./utils";

export default function CopyButton({ content, shorten, showNotification }) {

  const handleCopyClick = (event) => {
    event.preventDefault();
    navigator.clipboard.writeText(content);
    showNotification("Copied to clipboard", "")
  };

  return (
    <div className="flex flex-row items">
      <button className="btn btn-ghost" style={{ textTransform: "none" }} onClick={handleCopyClick}>
        {shorten ? shortenedAddress(content) : content}
      </button>
    </div>
  );
}
