import axios from "axios";
import { auth } from "../../firebase";
import Alert from "./alert";

const backend = process.env.REACT_APP_BACKEND_URL;

export default function GetEth({ onSuccess, onClickConfirm }) {
  const handleExecute = (event) => {
    onClickConfirm();
    const recipientAddress = document.getElementById("recipient-address").value;
    const amount = document.getElementById("amount").value;

    auth.currentUser
      .getIdToken()
      .then((idToken) => {
        console.log(idToken + " " + recipientAddress + " " + amount);
        // Make the POST request to the /getETH endpoint
        axios
          .post(
            `${backend}/getETH`,
            {
              recipientAddress,
              amount,
            },
            {
              headers: {
                Authorization: idToken,
              },
            }
          )
          .then((response) => {
            // Handle the response
            onSuccess(
              `ETH is sent to ${recipientAddress}`,
              "Go to dashboard to view the transaction."
            );
          })
          .catch((error) => {
            // Handle the error
            console.error(error);
          });
      })
      .catch((error) => {
        // Handle the error
        console.error(error);
      });
  };

  return (
    <form method="dialog" className="modal-box">
      <input
        type="text"
        id="recipient-address"
        placeholder="recipient address"
        className="input w-full max-w-md"
      />
      <input
        type="number"
        id="amount"
        placeholder="amount"
        className="input input-bordered w-full max-w-xs mt-5"
      />
      <div className="modal-action">
        <div className="flex justify-between">
          <button className="btn mr-2" onClick={handleExecute}>
            Execute
          </button>{" "}
          {/* Added onClick event and attached handleExecute function */}
          <button className="btn ml-2">Close</button>
        </div>
      </div>
    </form>
  );
}
