import NetworkSelector from "./networkSelector";
import { useState } from "react";
import { auth } from "./firebase";
import { useNavigate } from "react-router-dom";
const apiUrl = "localhost:3000";
export default function LaunchTestnet() {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [blockNumber, setBlockNumber] = useState("");
  const [network, setNetwork] = useState(-1);
  const navigate = useNavigate();

  const launchTestnet = async () => {
    try {
      console.log(name, description, blockNumber, network);
      // const response = await axios.post("http://localhost:3000/testnet", {
      //     name,
      //     description,
      //     blockNumber,
      //     network
      // }, {
      //     headers: {
      //         'Content-Type': 'application/json',
      //         Authorization: `Bearer ${await auth.currentUser.getIdToken()}`
      //     }
      // });
      // Handle the response or any necessary follow-up actions
      // console.log(response.data);
    } catch (error) {
      // Handle the error
      console.error(error);
    }
  };

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="mt-5">
        <NetworkSelector selectNetwork={setNetwork} />
      </div>
      <div className="mt-5">
        <button className="btn btn-outline" onClick={launchTestnet}>
          Launch testnet
        </button>
      </div>
    </div>
  );
}
