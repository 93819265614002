import axios from "axios";
import { auth } from "../../firebase";
import Alert from "./alert";

const backend = process.env.REACT_APP_BACKEND_URL;

export default function ResetTestnet({ onClickConfirm, onSuccess }) {
  const handleConfirm = (event) => {
    onClickConfirm();
    auth.currentUser
      .getIdToken()
      .then((idToken) => {
        // Make the POST request to the /getETH endpoint
        axios
          .post(
            `${backend}/resetTestnet`,
            {},
            {
              headers: {
                Authorization: idToken,
              },
            }
          )
          .then((response) => {
            // Handle the response
            onSuccess(
              `Testnet node has been reset successfully!`,
              ""
            );
          })
          .catch((error) => {
            // Handle the error
            console.error(error);
          });
      })
      .catch((error) => {
        // Handle the error
        console.error(error);
      });
  };

  return (
    <form method="dialog" className="modal-box">
      <p>
        Are you sure you want to reset the testnet?
        <br />
        All your current testnet configs and transactions will be reset
      </p>
      <div className="modal-action">
        <div className="flex justify-between">
          <button className="btn mr-2" onClick={handleConfirm}>
            Confirm
          </button>
          {/* Added onClick event and attached handleExecute function */}
          <button className="btn ml-2">Close</button>
        </div>
      </div>
    </form>
  );
}
