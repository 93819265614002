import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import Main from './components/main';
import Landing from './components/landing';

import SignIn from './components/signIn';
import Faq from './components/faq';
import LaunchTestnet from './components/launchTestnet';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/landing" element={<Landing />} />
        <Route path="/" element={<Main />} />
        <Route exact path="/dashboard" element={<Main />} />
        <Route exact path="/sign-in" element={<SignIn signIn={false} />} />
        <Route exact path="/launch" element={<LaunchTestnet />} />
      </Routes>
    </BrowserRouter>
  );
}
export default App;
