import axios from "axios";
import { auth } from "../../firebase";

const backend = process.env.REACT_APP_BACKEND_URL;

export default function GetERC20({ onSuccess, onClickConfirm }) {
  const handleExecute = () => {
    const recipientAddress = document.getElementById("recipient-address").value;
    const tokenAddress = document.getElementById("token-address").value;
    const amount = document.getElementById("amount").value;

    onClickConfirm();

    auth.currentUser
      .getIdToken()
      .then((idToken) => {
        // Make the POST request to the /getERC20 endpoint
        axios
          .post(
            `${backend}/getERC20`,
            {
              recipientAddress,
              tokenAddress,
              amount,
            },
            {
              headers: {
                Authorization: idToken,
              },
            }
          )
          .then((response) => {
            // Handle the response
              onSuccess(
              `${amount} ${tokenAddress} token is sent to ${recipientAddress}`,
              "Go to dashboard to view the transaction."
            );
            onConfirm();
          })
          .catch((error) => {
            // Handle the error
            console.error(error);
          });
      })
      .catch((error) => {
        // Handle the error
        console.error(error);
      });
  };

  return (
    <form method="dialog" className="modal-box">
      <input
        type="text"
        id="recipient-address" // Added an id for easier access
        placeholder="recipient address"
        className="input input-bordered w-full max-w-xs"
      />
      <input
        type="text"
        id="token-address" // Added an id for easier access
        placeholder="token address"
        className="input input-bordered w-full max-w-xs mt-5"
      />
      <input
        type="number"
        id="amount" // Added an id for easier access
        placeholder="amount"
        className="input input-bordered w-full max-w-xs mt-5"
      />
      <div className="modal-action">
        <div className="flex justify-between">
          <button className="btn mr-2" onClick={handleExecute}>
            Execute
          </button>{" "}
          {/* Added onClick event and attached handleExecute function */}
          <button className="btn ml-2">Close</button>
        </div>
      </div>
    </form>
  );
}
