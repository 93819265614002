import { Fragment, useEffect, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import LaunchTestnet from "./launchTestnet.jsx";
import Composer from "./composer/index.jsx";
import { auth } from "./firebase.js";
import useAuthHook from "../hooks/authHook.js";
import { useNavigate } from "react-router-dom";
import Faq from "./faq.jsx";
import CustomizeTestEnv from "./dashboard/index.jsx";
import Dashboard from "./dashboard/index.jsx";
import ethLogo from "../assets/eth-diamond-purple-white.jpg";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const backend = process.env.REACT_APP_BACKEND_URL;

export default function Main() {
  const navigate = useNavigate();
  useAuthHook();
  const [userDisplayName, setUserDisplayName] = useState("");
  const [currentTestnetEnv, setCurrentTestnetEnv] = useState(
    "Ethereum mainnet fork"
  );
  const [loading, setLoading] = useState(true);
  const [currentMenu, setCurrentMenu] = useState(0);
  const [testnetUrl, setTestnetUrl] = useState("NotFound");

  const [navigation, setNavigation] = useState([
    { name: "Dashboard", current: true },
    // { name: "Composer", current: false },
    { name: "FAQ", current: false },
  ]);

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      // redirect to sign in page
      navigate("/sign-in");
    } catch (error) {
      console.error(error);
    }
  };

  const goToDashboard = () => {
    setCurrentMenu(0);
  };

  const handleNavigationClick = (item) => {
    setNavigation((prevNavigation) =>
      prevNavigation.map((navItem) => ({
        ...navItem,
        current: navItem.name === item.name,
      }))
    );
    let menu = 0;
    switch (item.name) {
      case "Dashboard":
        menu = 0;
        break;
      case "Composer":
        menu = 1;
        break;
      case "FAQ":
        menu = 2;
        break;
      default:
        menu = 0;
    }
    setCurrentMenu(menu);
  };

  const userNavigation = [
    { name: "Sign out", href: "#", onClick: handleSignOut },
  ];

  const testnetEnvs = [{ name: "Ethereum mainnet fork" }];

  useEffect(() => {
    const fetchUserDisplayName = async () => {
      try {
        // Wait until the user is authenticated
        await new Promise((resolve) => {
          const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
              resolve();
            }
          });
          return unsubscribe;
        });

        // Get the Firebase auth token
        const currentUser = await auth.currentUser;

        setUserDisplayName(currentUser.displayName);

        setLoading(false); // Set loading to false when user authentication is completed
      } catch (error) {
        console.error(error);
        setLoading(false); // Set loading to false even if there's an error
      }
    };

    const fetchTestnetUrl = async () => {
      try {
        // Wait until the user is authenticated
        await new Promise((resolve) => {
          const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
              resolve();
            }
          });
          return unsubscribe;
        });

        // Get the Firebase auth token
        const authToken = await auth.currentUser?.getIdToken();

        // Create the headers object with the authorization header
        const headers = {
          "Content-Type": "application/json",
          Authorization: `${authToken}`,
        };

        const response = await fetch(`${backend}/getTestnetNodeInfo`, {
          method: "GET",
          headers,
        });
    
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
    
        const data = await response.json();
        console.log('............')
        console.log("data url", data.uri);
        setTestnetUrl(data.uri);
      } catch (error) {
        console.error("Error fetching testnet url:", error);
      }
    };

    // Call the fetchUserDisplayName function when the component mounts
    fetchUserDisplayName();
    fetchTestnetUrl();
  }, []);

  if (loading) {
    // Return a loading indicator or placeholder while waiting for user authentication
    return (
      <div className="flex justify-center">
        <span className="loading loading-bars loading-lg mt-20"></span>
      </div>
    );
  }

  return (
    <>
      <div className="min-h-full">
        <Disclosure as="nav" className="border-b border-gray-200 bg-white">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 justify-between">
                  <div className="flex">
                    <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          className={classNames(
                            item.current
                              ? "border-indigo-500 text-gray-900"
                              : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                            "inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium"
                          )}
                          aria-current={item.current ? "page" : undefined}
                          onClick={(e) => {
                            e.preventDefault();
                            handleNavigationClick(item);
                          }}
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                  <div className="hidden sm:ml-6 sm:flex sm:items-center">
                    <Menu as="div" className="relative ml-3">
                      <div>
                        <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                          <img
                            className="h-8 w-8 rounded-full"
                            src={ethLogo}
                            alt=""
                          />
                          {currentTestnetEnv || "Loading..."}
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {testnetEnvs.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <a
                                  href={item.href}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}
                                  onClick={item.onClick}
                                >
                                  {item.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                    <Menu as="div" className="relative ml-8">
                      <div>
                        <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                          {userDisplayName || "Loading..."}
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <a
                                  href={item.href}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}
                                  onClick={item.onClick}
                                >
                                  {item.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                  <div className="-mr-2 flex items-center sm:hidden">
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>
            </>
          )}
        </Disclosure>

        <div className="py-10">
          <main>
            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 space-y-2">
              {userDisplayName && (
                <>
                  {currentMenu === 0 && <Dashboard testnetUrlParam={testnetUrl} />}
                  {currentMenu === 2 && <Faq />}
                </>
              )}
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
