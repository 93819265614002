import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyD2OnBEOxp_ie817F6hjgFphlDI8DZ2UGk",
    authDomain: "taas-8eab5.firebaseapp.com",
    projectId: "taas-8eab5",
    storageBucket: "taas-8eab5.appspot.com",
    messagingSenderId: "452286423934",
    appId: "1:452286423934:web:8e650d49445eb71c1b492f",
    measurementId: "G-KH5R120Q5E"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
